import request from '@/utils/request'

// 新增初始化
export function getaddInit() {
    return request({
        url: "/api/financeAccount/addInit",
        method: "get"
    })
}

// 新增数据
export function getAddData(data) {
   return request({
       url: '/api/financeAccount',
       data,
       method: 'post'
   })
}

// 查询初始化
export function getPageInit() {
  return request({
    url: '/api/financeAccount/pageInit',
    method: 'get'
  })
}

// 查询分页数据
export function getInitSearch(params) {
   return request({
      url: '/api/financeAccount/listPage',
      params,
      method: 'get'
   })
}

// 编辑数据
export function editData(data) {
   return request({
       url: '/api/financeAccount',
       data,
       method: 'put'
   })
}

// 设置默认账号
export function updateDefaultAccount(id) {
   return request({
      url: "/api/financeAccount/updateDefaultAccount/" + id,
      method: 'put'
   })
}

// 启用 禁用账号
export function updateAccountStatus(id) {
   return request({
     url: "/api/financeAccount/updateAccountStatus/" + id, 
     method: 'put'
   })
}

// 添加更新类别管理
export function addOrUpdateCategory(data) {
   return request({
     url: '/api/financeAccount/addOrUpdateCategory',
     data,
     method: 'put'
   })
}

// 查询类别管理(账户类型)
export function getCategoryInit() {
    return request({
      url: '/api/financeAccount/categoryInit',
      method: 'get'
    })
}

// 类别管理删除
export function deleteCategory(id) {
    return request({
       url: "/api/financeAccount/deleteCategory/" + id,
       method: 'delete'
    })
}

//导出
export function exportExcel(params) {
   return request({
       method:'get',
       url:'/api/financeAccount/exportExcel',
       params
   });
}

//查看下载报告
export function exportRecort() {
   return request({
       url:'/api/financeAccount/exportRecord',
       method:'get',
   });
}